function resize() {
  // card heights
  const levels = document.getElementsByClassName('card-text-level');
  let maxHeight = 0;
  Array.from(levels).forEach((item) => {
    item.style.height = 'auto';
  });
  Array.from(levels).forEach((item) => {
    if (item.offsetHeight > maxHeight) {
      maxHeight = item.offsetHeight;
    }
  });
  Array.from(levels).forEach((item) => {
    item.style.height = `${maxHeight}px`;
  });

  // booking frame
  const ww = window.innerWidth;
  const frame = document.getElementById('book-frame');
  if (ww > 900) {
    frame.height = 510;
    frame.src = 'https://www.saunaonline.fi/publicmodules/publiccalendarmediums/saunatilat-oulu-asema-skyline';
    frame.width = 560;
  } else {
    frame.height = 300;
    frame.src = 'https://www.saunaonline.fi/publicmodules/publiccalendarmicro/saunatilat-oulu-asema-skyline';
    frame.width = 310;
  }
}

resize();
window.addEventListener('resize', resize);

const form = document.forms[0];
form.addEventListener('submit', (e) => {
  e.preventDefault();

  const data = new FormData(form);
  const data2 = {};
  data.forEach((value, key) => {
    data2[key] = value;
  });

  fetch('/mailer.php', {
    body: JSON.stringify(data2),
    method: 'POST',
  });

  form.classList.add('d-none');
  document.getElementById('form-submit').classList.remove('d-none');
});
